.dot-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .dot-loader span {
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    animation: dotAnimation 1.5s infinite;
  }
  
  .dot-loader span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot-loader span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot-loader span:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes dotAnimation {
    0%, 80%, 100% {
      background-color: gray;
      transform: scale(1);
    }
    40% {
      background-color: blue;
      transform: scale(1.5);
    }
  }
  